@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-width-1080 {
	max-width: 1080px;
}

.custom-h-full {
	height: calc(100% - 3rem);
}

.custom-explicit-link {
	@apply underline;
	@apply text-blue-500;

	&:hover {
		@apply text-blue-600;
	}
}

/* Fixes a create react app hot reloading issue: */
iframe {
	pointer-events: none;
}
